<template>
  <div>
    <v-card tile flat class="card-shadow">
      <v-card-title class="grey lighten-5 align-center py-2">
        <data-type-picker
          :value="dataType"
          :loading="calendarLoading"
          :disabled="calendarLoading"
          hide-details
          dense
          @input="$emit('update:dataType', $event)"
        />
      </v-card-title>
      <v-card-title class="grey lighten-5 align-center py-0">
        <month-picker v-model="selectedMonth" :loading="calendarLoading" :disabled="calendarLoading" />
        <v-spacer />
        <v-btn class="mr-3" fab x-small depressed color="grey lighten-2" :loading="calendarLoading" @click.stop="prev">
          <v-icon small>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab x-small depressed color="grey lighten-2" :loading="calendarLoading" @click.stop="next">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>
      <template v-if="calendarLoading">
        <v-card flat height="410">
          <v-card-text class="fill-height">
            <v-container fill-height justify-center align-center>
              <v-layout justify-center align-center>
                <v-progress-circular indeterminate size="40" color="primary" />
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </template>
      <v-sheet height="410" v-else>
        <v-calendar
          v-model="calendarDate"
          type="month"
          ref="calendar"
          color="primary"
          :start="beginDate"
          :end="endDate"
          :locale="$store.state.language"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          style="border-left:1px solid #ddd; border-top: 1px solid #ddd"
          @click:day="onClickEvent"
          @click:date="onClickEvent"
        >
          <template v-slot:day="{ date }">
            <template v-for="(event, index) in eventsMap[date]" v-if="index <= 2">
              <v-icon :size="10" :color="event.color">mdi-circle</v-icon>
            </template>
            <template v-else-if="index === 3">
              <span class="caption pt-2">+{{ eventsMap[date].length - 3 }}</span>
            </template>
          </template>
        </v-calendar>
      </v-sheet>
    </v-card>
    <event-detail />
  </div>
</template>

<script>
  export default {
    name: "calendar",
    components: {
      "month-picker": () => import("./month-picker"),
      "event-detail": () => import("./event-detail"),
      DataTypePicker: () => import("./DataTypePicker")
    },
    props: {
      calendarLoading: Boolean,
      beginDate: String,
      endDate: String,
      events: Array,
      dataType: String
    },
    data: vm => ({
      calendarDate: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM-DD")
    }),
    watch: {
      calendarDate(curr, prev) {
        let currMonth = this.$moment(curr).format("MM");
        let prevMonth = this.$moment(prev).format("MM");
        if (currMonth !== prevMonth) {
          this.emitBeginAndEndDates(curr);
        }
      }
    },
    computed: {
      selectedMonth: {
        get() {
          return this.$moment(this.calendarDate).format("YYYY-MM");
        },
        set(v) {
          this.calendarDate = this.$moment(v)
            .startOf("month")
            .format("YYYY-MM-DD");
        }
      },
      eventsMap() {
        const map = {};
        this.events.forEach(event => (map[event.date] = map[event.date] || []).push(event));
        return map;
      }
    },
    methods: {
      emitBeginAndEndDates(date) {
        this.$emit(
          "update:begin-date",
          this.$moment(date)
            .startOf("month")
            .format("YYYY-MM-DD")
        );
        this.$emit(
          "update:end-date",
          this.$moment(date)
            .endOf("month")
            .format("YYYY-MM-DD")
        );
        this.$emit("update:calendar-date", date);
      },
      prev() {
        this.$refs.calendar.prev();
      },
      next() {
        this.$refs.calendar.next();
      },
      onClickEvent({ date }) {
        if (Array.isArray(this.eventsMap[date]) && this.eventsMap[date].length) {
          const payload = {
            date: date,
            data: this.eventsMap[date],
            dataType: this.dataType
          };
          this.$eventBus.$emit("calendar:detail", payload);
        }
      }
    }
  };
</script>
